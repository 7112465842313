import {
  useState,
  FC,
  ReactElement,
} from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@material-ui/core';

// component
import PortlLogo from '../../components/PortlLogo';
import Button from '../../components/Button';

// context
import { useUserContext } from '../../context/UserProvider';

// helpers
import { emailYup, passwordYup } from '../../helpers/validation';

// api
import {
  loginUser,
} from '../../api';

const validationSchema = yup.object({
  email: emailYup,
  password: passwordYup,
});

const LoginContainer = styled.div`
  width: 360px;
  margin: 20vh auto 0;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 10px;
`;

const PortlLogoStyled = styled(PortlLogo)`
  margin-bottom: 20px;
`;

const Space = styled.div`
  height: 20px;
`;

const Login: FC = (): ReactElement => {
  const { setUser } = useUserContext();

  const [isDisable, setDisable] = useState<boolean>(false);

  const handleSubmit = async (values: {
    email: string,
    password: string
  }, { setFieldError }: {
    setFieldError: (field: string, msg: string) => void
  }): Promise<null> => {
    setDisable(true);
    const response = await loginUser(values);
    setDisable(false);

    if (response && 'error' in response) {
      setFieldError('email', 'Unable to login with the provided credentials.');
      return null;
    }

    // Or update to null/underfined to reload
    setUser(response);

    return null;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <LoginContainer>
      <PortlLogoStyled />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <Space />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          variant="outlined"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Space />
        <Button
          type="submit"
          variant="contained"
          disabled={isDisable}
        >
          Login
        </Button>
      </form>
    </LoginContainer>
  );
};

export default Login;
