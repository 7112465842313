import axios from 'axios';
// const test = require('dotenv').config();
// dotenv does not work on client side -> https://github.com/motdotla/dotenv/issues/323

// Endpoints
const loginEndpoint = '/streets/login';
const infoEndpoint = '/streets/info';
const hubspotTicketEndpoint = '/streets/hubspot-ticket';
const deviceLoingEndpoint = '/streets/login-device';
const deviceEndpoint = '/streets/device';

const axiosConfig = {
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

interface SimpleResponseProps {
  error?: {}
  msg?: string
}

interface LoginUserProps {
  email: string,
  password: string
}

export const loginUser = async (credentials: LoginUserProps): Promise<UserType | ErrorType> => {
  try {
    // data is type any
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}${loginEndpoint}`,
      credentials,
      axiosConfig,
    );

    return data;
  } catch (error) {
    return { error };
  }
};

export const getUserInfo = async (): Promise<UserType | ErrorType> => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}${infoEndpoint}`,
      axiosConfig,
    );

    return data;
  } catch (error) {
    return { error };
  }
};

interface HubspotTicketProps {
  subject: string
  message: string
}

export const createHubspotTicket = async (values: HubspotTicketProps)
  : Promise<SimpleResponseProps> => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}${hubspotTicketEndpoint}`,
      values,
      axiosConfig,
    );

    return data;
  } catch (error) {
    return { error };
  }
};

export const loginDevice = async (deviceId: string):
 Promise<LoginDeviceReturnType | ResponseError> => {
  try {
    const { data }: { data: LoginDeviceReturnType | ResponseError } = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}${deviceLoingEndpoint}/${deviceId}`,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const getDevice = async (deviceId: string): Promise<any> => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}${deviceEndpoint}/${deviceId}`,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const updateDeviceName = async (deviceId: string, name: string): Promise<any> => {
  try {
    // PUT gives CORS for some reason, at least on localhost
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}${deviceEndpoint}/${deviceId}`,
      { name },
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};
