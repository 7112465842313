import { FC } from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const Alert: FC<AlertProps> = ({ severity, onClose, children }) => (
  <MuiAlert
    elevation={8}
    variant="filled"
    severity={severity}
    onClose={onClose}
  >
    {children}
  </MuiAlert>
);

export default Alert;
