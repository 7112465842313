import { FC, ReactElement } from 'react';
// import styled from 'styled-components';
import queryString from 'query-string';

const parseErrorObject = (query: queryString.ParsedQuery<string>) => {
  const commentBody = JSON.stringify(query.errorMsg, null, 2);

  const requesterEmail = (query?.email) ? query.email.toString() : 'deviceNotConnectedStreets@portlmedia.com';

  return { commentBody, requesterEmail };
};

const Error: FC = (): ReactElement => {
  const query: queryString.ParsedQuery<string> = queryString.parse(window.location.search);

  const { commentBody, requesterEmail } = parseErrorObject(query);

  return (
    <>
      <div>{commentBody}</div>
      <div>{requesterEmail}</div>
    </>
  );
};

export default Error;
