import { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

// components
import Header from '../Header';
import HideOnScroll from '../HideOnScroll';

interface HeaderProps {
  title: string
  children: ReactNode
}

const Container = styled.div`
  padding: 75px 16px 20px;
  overflow: auto;
`;

const BodyTemplate: FC<HeaderProps> = ({ title, children }): ReactElement => (
  <Container>
    {/** HIDEONSCROLL IS NOT WORKING */}
    <HideOnScroll>
      <Header title={title} />
    </HideOnScroll>
    {children}
  </Container>
);

export default BodyTemplate;
