import {
  ReactElement, forwardRef, MouseEvent, useState,
} from 'react';
import styled from 'styled-components';
import {
  AppBar,
  Avatar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';

// context
import { useUserContext } from '../../context/UserProvider';

interface HeaderProps {
  title: string
}

type Ref = HTMLButtonElement;

const AppBarStyled = styled(AppBar)`
  background-color: #169aff;
  color: #fff;
`;

const ToolbarStyled = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

const ButtonStyled = styled.button`
  border:none;
  background-color:transparent;
  outline:none;
`;

const Header = forwardRef<Ref, HeaderProps>(({ title }, ref): ReactElement => {
  const { setCurrentAccount, user, currentAccount } = useUserContext();

  const [anchorEl, setAnchorEl] = useState<Element>();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleAccountSelection = (acc: AccountType) => {
    setCurrentAccount(acc);
    handleClose();
  };

  if (!user || 'error' in user) {
    return (
      <>
        User Error:
        {user?.error}
      </>
    );
  }

  return (
    <AppBarStyled ref={ref}>
      <ToolbarStyled>
        <Typography variant="h6">portl</Typography>
        <Typography variant="h6">{title}</Typography>
        <ButtonStyled onClick={handleClick} id="company-menu-buttom">
          <Avatar alt={currentAccount?.name} src={currentAccount?.logoUrl} />
        </ButtonStyled>
        <Menu
          id="company-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {user.accounts?.map(
            (acc) => (
              <MenuItem
                key={acc._id}
                onClick={() => handleAccountSelection(acc)}
                selected={currentAccount?._id === acc._id}
              >
                {acc.name}
              </MenuItem>
            ),
          )}
        </Menu>

      </ToolbarStyled>
    </AppBarStyled>
  );
});

export default Header;
