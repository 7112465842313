// helper
import createCtx from './helper';

interface UserContextProps {
  user: UserType | ErrorType | undefined
  setUser: (newUser: UserType | ErrorType) => void
  currentAccount: AccountType | undefined
  setCurrentAccount: (account: AccountType) => void
  refetchUser: () => void
}

export const [useUserContext, CurrentUserProvider] = createCtx<UserContextProps>();
