import { FC, ReactElement } from 'react';
import styled from 'styled-components';

import {
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

interface DeviceCardProps {
  model: string
  orientation: string
  name: string
  approved: boolean
  createdOn: string
}

interface StatusProps {
  approved: boolean
}

const CardStyled = styled(Card)`
  background-color: #eee;
  box-shadow: 4px 4px 6px rgba(0,0,0,0.16);
  max-width: 330px;
  margin: 0 auto 15px;
`;

const CardContentStyled = styled(CardContent)`
  padding-top: 0;
  line-height: 25px;
`;

const StatusContainer = styled.p<StatusProps>`
  padding: 1px 4px;
  margin: 0;
  display: inline;
  color: #fff;
  width: fit-content;
  border-radius: 9px;
  background-color: ${({ approved }) => (approved ? '#51c900' : '#c2c200')};
`;

const Status: FC<StatusProps> = ({ approved }): ReactElement => (
  <StatusContainer approved={approved}>
    {approved ? ' Active ' : ' Awaiting Review '}
  </StatusContainer>
);

const DeviceCard: FC<DeviceCardProps> = ({
  name, createdOn,
  model, orientation,
  approved,
}): ReactElement => (
  <CardStyled>
    <CardHeader
      title={name}
      subheader={new Date(createdOn).toDateString()}
    />
    <CardContentStyled>
      Status -
      {' '}
      <Status approved={approved} />
      <br />
      Model -
      {' '}
      {model}
      <br />
      Orientation -
      {' '}
      {orientation}
      <br />
    </CardContentStyled>
  </CardStyled>
);

export default DeviceCard;
