import { FC, ReactElement } from 'react';
import {
  Slide,
  useScrollTrigger,
} from '@material-ui/core';

interface HideOnScrollProps {
  children: ReactElement
}

const HideOnScroll: FC<HideOnScrollProps> = ({ children }): ReactElement => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
