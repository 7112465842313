import * as yup from 'yup';

const emailYup = yup
  .string()
  .email('Enter a valid email')
  .required('Email is required');

const passwordYup = yup
  .string()
  .required('Password is required');

export {
  emailYup,
  passwordYup,
};
