import {
  FC,
  ReactElement,
  useState,
} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';

import TvRoundedIcon from '@material-ui/icons/TvRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';

const BottomNavigationStyled = styled(BottomNavigation)`
  background-color: #169aff;
`;

const BottomNavigationActionStyle = styled(
  (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading -- couldn't make it work
    <BottomNavigationAction {...props} />
  ),
)`
  color: #fff;
`;

const BottomNav: FC = (): ReactElement => {
  const [value, setValue] = useState(0);

  // This Bottom Navigation is recommended to add no more that 5 options
  return (
    <BottomNavigationStyled
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
    >
      <BottomNavigationActionStyle
        component={Link}
        id="linkToAccount"
        to="/account"
        label="Account"
        icon={<AccountCircleRoundedIcon />}
      />
      <BottomNavigationActionStyle
        component={Link}
        id="linkToDevices"
        to="/devices"
        label="Devices"
        icon={<TvRoundedIcon />}
      />
      <BottomNavigationActionStyle
        component={Link}
        id="linkToHelp"
        to="/help"
        label="Help"
        icon={<HelpRoundedIcon />}
      />
    </BottomNavigationStyled>
  );
};

export default BottomNav;
