import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import {
  Avatar,
} from '@material-ui/core';

// components
import BodyTemplate from '../../components/BodyTemplate';

// context
import { useUserContext } from '../../context/UserProvider';

const AvatarStyled = styled(Avatar)`
  width: 100px;
  height: 100px;
  margin: 15px auto 0;
`;

interface ContentProps {
  size: string
}

const ContentStyled = styled.div<ContentProps>`
  text-align: center;
  margin: 15px auto 0;
  font-size: ${({ size }) => size};
  font-weight: 600;
`;

const Account: FC = (): ReactElement => {
  const { user, currentAccount } = useUserContext();

  if (!user || 'error' in user) {
    return (
      <>
        User Error:
        {user?.error}
      </>
    );
  }

  return (
    <BodyTemplate title="Account">
      <AvatarStyled
        alt={user.firstName}
        src={user.profileUrl}
      />
      <ContentStyled size="28px">
        {user.firstName}
        {' '}
        {user.lastName}
      </ContentStyled>
      <ContentStyled size="20px">
        {currentAccount?.name?.toUpperCase()}
        {' - '}
        {currentAccount?.role?.toLowerCase()}
      </ContentStyled>
    </BodyTemplate>
  );
};

export default Account;
