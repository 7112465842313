import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  size?: string,
  className?: string,
  color?: string
}

const PortlLogoConatiner = styled.p<Props>`
    text-decoration: none;
    z-index: 1;
    font-size: ${(({ size }) => size || '65px')};
    font-weight: bold;
    line-height: 1.22;
    letter-spacing: -1.5px;
    text-align: left;
    color: ${(({ color }) => color || '#1fb6ff')};;
    margin: 0;

    :hover {
        cursor: pointer;
    }
`;

const PortlLogo: FC<Props> = ({ size, className, color }): JSX.Element => (
  <PortlLogoConatiner size={size} className={className} color={color}>
    portl
  </PortlLogoConatiner>
);

export default PortlLogo;
