import {
  FC, useState, useEffect, ReactElement,
} from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { CurrentUserProvider } from './context/UserProvider';

// components
import AuthRoute from './components/AuthRoute';
import PrivateRoute from './components/PrivateRoute';

// views
import Help from './views/Help';
import Login from './views/Login';
import Devices from './views/Devices';
import Account from './views/Account';
import Error from './views/Error';

// api
import { getUserInfo } from './api';

const App: FC = (): ReactElement => {
  const [user, setUser] = useState<UserType | ErrorType>();
  const [currentAccount, setCurrentAccount] = useState<AccountType>();

  const addUser = (newUser: UserType | ErrorType): void => {
    setUser(newUser);

    if ('accounts' in newUser) {
      setCurrentAccount(newUser.accounts && newUser.accounts[0]);
    }
  };

  useEffect(() => {
    let isMounted = true;

    async function fetchUser() {
      const response = await getUserInfo();
      isMounted && addUser(response);
    }

    if (!user) fetchUser();

    return () => {
      isMounted = false;
    };
  }, [user]);

  const refetchUser = async () => {
    addUser(await getUserInfo());
  };

  return (
    <CurrentUserProvider
      value={{
        user,
        setUser,
        currentAccount,
        setCurrentAccount,
        refetchUser,
      }}
    >
      <Switch>
        <Route path="/error" exact>
          <Error />
        </Route>
        <AuthRoute path="/login" exact>
          <Login />
        </AuthRoute>
        <PrivateRoute path="/devices" exact>
          <Devices />
        </PrivateRoute>
        <PrivateRoute path="/account" exact>
          <Account />
        </PrivateRoute>
        <PrivateRoute path="/help" exact>
          <Help />
        </PrivateRoute>
        <Redirect to="/account" />
      </Switch>
    </CurrentUserProvider>
  );
};

export default App;
