import {
  FC, ReactElement,
} from 'react';

// components
import BodyTemplate from '../../components/BodyTemplate';
import DeviceCard from '../../components/DeviceCard';

// context
import { useUserContext } from '../../context/UserProvider';

const Devices: FC = (): ReactElement => {
  const { currentAccount } = useUserContext();

  return (
    <BodyTemplate title="Devices">
      {currentAccount?.devices.map(
        ({
          model,
          id,
          orientation,
          name,
          approved,
          createdOn,
        }) => (
          <DeviceCard
            key={id}
            name={name}
            model={model}
            orientation={orientation}
            approved={approved}
            createdOn={createdOn}
          />
        ),
      )}
    </BodyTemplate>
  );
};

export default Devices;
