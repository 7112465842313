import { FC, ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

// context
import { useUserContext } from '../../context/UserProvider';

interface AuthRouteProps {
  children: ReactNode;
  path: string;
  exact?: boolean;
}

const AuthRoute: FC<AuthRouteProps> = ({
  path,
  exact,
  children,
}) => {
  const { user } = useUserContext();

  if (!user) return <CircularProgress />;

  return ('error' in user)
    ? (
      <Route path={path} exact={exact}>
        {children}
      </Route>
    )
    : (<Redirect to={`/account${window.location.search}`} />);
};

export default AuthRoute;
