import {
  FC, ReactNode, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

// component
import BottomNav from '../BottomNav';

// context
import { useUserContext } from '../../context/UserProvider';

// api
import { getDevice, loginDevice, updateDeviceName } from '../../api';

interface Params {
  id?: string;
}

interface PrivateRouteProps {
  children: ReactNode;
  path: string;
  exact?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: space-between;
`;

const PrivateRoute: FC<PrivateRouteProps> = ({ path, exact, children }) => {
  const { user, refetchUser } = useUserContext();
  const params: Params = queryString.parse(window.location.search);
  const history = useHistory();
  const [deviceLoggingIn, setDeviceLoggingIn] = useState<boolean>(false);

  // Login in device if params.id exists
  useEffect(() => {
    const loginDeviceFromId = async (): Promise<LoginDeviceReturnType | null> => {
      if (!params.id || !user || 'error' in user || deviceLoggingIn) return null;
      setDeviceLoggingIn(true);
      const loginResponse: LoginDeviceReturnType | ResponseError = await loginDevice(
        params.id,
      );
      history.replace({
        search: '',
      });

      if (loginResponse && 'error' in loginResponse) {
        // eslint-disable-next-line no-console
        console.log(
          'Error returned from streets/login-device',
          loginResponse.error.response,
        );
        if (
          loginResponse
          && 'error' in loginResponse
          && loginResponse.error.response.status === 401
        ) {
          history.push('/unAuth');
        }
        const deviceNotConnected = loginResponse.error.response
          && loginResponse.error.response.status === 502;

        const errorMsg = `POST/streets/login-device error - ${JSON.stringify(
          loginResponse.error.response,
        )}. Device ID: ${params.id}`;
        // eslint-disable-next-line no-unused-expressions
        deviceNotConnected
          ? history.push(`/error?errorMsg=${errorMsg}&deviceId=${params.id}`)
          : history.push(
            `/error?errorMsg=${errorMsg}&email=${
              'email' in user ? user.email : null
            }`,
          );
        return null;
      }

      // eslint-disable-next-line no-console
      console.log('Successfully logged device in.');

      const device = await getDevice(params?.id);

      if (device?.name && device.name === `Device ${params?.id}`) {
        // eslint-disable-next-line no-alert
        const newName = window.prompt('How would you like to name this device?', device.name);
        if (newName) {
          await updateDeviceName(params?.id, newName);
          await refetchUser();
        }
      }

      setDeviceLoggingIn(false);

      return loginResponse;
    };

    loginDeviceFromId();
  }, [params.id, user, deviceLoggingIn]);

  if (!user) return <CircularProgress />;

  if (deviceLoggingIn) return <CircularProgress title="Logging device in..." />;
  return 'error' in user ? (
    <Redirect to={`/login${window.location.search}`} />
  ) : (
    <Container>
      <Route path={path} exact={exact}>
        {children}
      </Route>
      <BottomNav />
    </Container>
  );
};

export default PrivateRoute;
