import { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  Snackbar,
} from '@material-ui/core';

// components
import BodyTemplate from '../../components/BodyTemplate';
import Button from '../../components/Button';
import Alert from '../../components/Alert';

// api
import { createHubspotTicket } from '../../api';

const validationSchema = yup.object({
  subject: yup
    .string()
    .required('Subject is required.'),
  message: yup
    .string()
    .required('Message is required.'),
});

const Space = styled.div`
  height: 15px;
`;

interface snackBarConfigProps {
  severity: 'success' | 'error'
  msg: string
}

const Help: FC = (): ReactElement => {
  const [isDisable, setDisable] = useState<boolean>(false);
  const [openSnackBar, setSnackBar] = useState<boolean>(false);
  const [snackBarConfig, setSnackBarConfig] = useState<snackBarConfigProps>({
    severity: 'success',
    msg: '',
  });

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(false);
  };

  const handleSubmit = async (values: {
    subject: string,
    message: string
  }): Promise<void> => {
    setDisable(true);
    const response = await createHubspotTicket(values);

    if (response.error) {
      setSnackBarConfig({
        msg: 'Error! Please try again later.',
        severity: 'error',
      });
    } else {
      setSnackBarConfig({
        msg: 'Email sent!',
        severity: 'success',
      });
    }
    setSnackBar(true);
    setDisable(false);
  };

  const formik = useFormik({
    initialValues: {
      subject: '',
      message: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <BodyTemplate title="Help">
      Need some help?
      <br />
      Please fill out the form below and a member of our team will be in touch.
      <Space />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="subject"
          name="subject"
          label="Subject"
          variant="outlined"
          value={formik.values.subject}
          onChange={formik.handleChange}
          error={formik.touched.subject && Boolean(formik.errors.subject)}
          helperText={formik.touched.subject && formik.errors.subject}
        />
        <Space />
        <TextField
          fullWidth
          id="message"
          name="message"
          label="Message"
          variant="outlined"
          multiline
          rows={8}
          value={formik.values.message}
          onChange={formik.handleChange}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
        />
        <Space />
        <Button
          type="submit"
          variant="contained"
          disabled={isDisable}
        >
          Send
        </Button>
      </form>
      <Snackbar
        open={openSnackBar}
        onClose={handleClose}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={snackBarConfig.severity}>
          {snackBarConfig.msg}
        </Alert>
      </Snackbar>
    </BodyTemplate>
  );
};

export default Help;
